import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {Button, Modal} from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Pagination} from 'antd';
import "antd/dist/antd.css";
import ENDPOINT from '../../ultils/EndPoint';
import './Products.scss';
import $, { trim } from 'jquery';
import {formatCurrency} from "../../helpers/function";
import {useHistory} from "react-router";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function Products() {
    // set const 
    const [listProducts, setListProducts] = useState([]);
    const [amountProduct, setAmountProduct] = useState('');

    const dispatch = useDispatch();
    // set for modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showed, setShowed] = useState(false);
    const handleClosed = () => {
        setAmountProduct('');
        setShowed(false);
        setMsg('');
        setSelectImported('1');
    }
    const handleShowed = () => setShowed(true);

    const [idStoreCurr, setIdStoreCurr] = useState('');
    const [nameStoreCurr, setNameStoreCurr] = useState('');
    const [typeList, setTypeList] = useState([]);
    const [brands, setBrands] = useState([]);
    const [category, setCategory] = useState([]);

    const [selectImported, setSelectImported] = useState('1');

    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setMsg] = useState('');
    const [checkSuccess, setCheckSuccess] = useState(false)

    const positive =  /^\+?(0|[1-9]\d*)$/


    var allCategory = [];
    category && category.map(cate => 
        {allCategory.push({
            name: cate.name, 
            id: cate.id,
            checkOrdin: 1
        })
            cate.childs !== null && cate.childs.map(cateChild =>{ 
                    allCategory.push({
                        name: cateChild.name, 
                        id: cateChild.id,
                        checkOrdin: 2
                    })
                    cateChild.Child !== null && cateChild.Child.map(cateChildLast => 
                        allCategory.push({
                            name: cateChildLast.name, 
                            id: cateChildLast.id,
                            checkOrdin: 3
                        })
                    )
                }
            )
        }
    )

    // set const for search
    const [typeListSelected, setTypeListSelected] = useState('');
    const [brandSelected, setbrandSelected] = useState('');
    const [categorySelected, setCategorySelected] = useState('');
    const [keyWord, setKeyWord] = useState('');
    const [statusSelected, setStatusSelected] = useState('');

    // set const for paginate
    const [numberPage,setNumberPage] = useState()
    const [currentPage, setcurrentPage] = useState(1);
    const [customersPerPage] = useState(10);

    // remove all whitespace
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    // set constans admin token 
    const admin_token = localStorage.getItem('User-Admin') 
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    // handle all value

    const selectImport = (e) => {
        setSelectImported(e.target.value)
    }
    const handleTypeList = (e) => {
        setTypeListSelected(e.target.value)
        setcurrentPage(1)
    }
    
    const handleCategory = (e) => {
        setCategorySelected(e.target.value)
        setcurrentPage(1)
    }

    const handleBrands = (e) => {
        setbrandSelected(e.target.value)
        setcurrentPage(1)
    }

    const handleStatus = (e) => {
        setStatusSelected(e.target.value)
        setcurrentPage(1)
    }

    const handleAmount = (e) => {
        setAmountProduct(e.target.value)
    }

    const handleRemoveAmount = (event) => {
        $('.amountProduct').removeClass('product_warning')
        validationMsg.amountProduct = ''
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const isValid = new RegExp("[0-9]").test(keyValue);
        if (!isValid) {
            event.preventDefault();
            return;
        }
    }



    // restart all
    const handleRestart = () => {
        setcurrentPage(1)
        setTypeListSelected('')
        setbrandSelected('')
        setCategorySelected('')
        setKeyWord('')
        setStatusSelected('')
        $("#selectCate").val("")
        $("#selectBrands").val("")
        $("#selectTypeList").val("")
        $("#searchCategory").val("")
        $("#selectStatus").val("")
    }

    const validateAll = () => {
        const msg = {};

            if(amountProduct.length <= 0){
                msg.amountProduct = 'Vui lòng nhập trường số lượng sản phẩm'
                $('.amountProduct').addClass('product_warning')
            }else if(amountProduct < 1
                || !positive.test(amountProduct)
                || amountProduct[0] === '0'){
                msg.amountProduct = 'Số lượng sản phẩm phải lớn hơn 0 và phải là số. Vui lòng nhập lại';
                $('.amountProduct').addClass('product_warning')
            }else if(amountProduct > 1000000000) {
                msg.amountProduct = 'Số lượng sản phẩm không được vượt quá.'
                $('.amountProduct').addClass('product_warning')
            }


        setValidationMsg(msg)
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }



    // call api create warrsehouse
    const createHouseProduct = new FormData();
    createHouseProduct.append('product_id', idStoreCurr);
    createHouseProduct.append('type', selectImported);
    createHouseProduct.append('quantity', amountProduct);

    const createHouse = () => {
        const isValidate = validateAll()
        if(!isValidate) return;

        return axios.post(`${ENDPOINT.WAREHOUSE}`, createHouseProduct,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Thêm sản phẩm vào kho thành công')
                    setTimeout(() => {
                        window.location.reload('/main/products')
                        setShowed(false);
                        setCheckSuccess(true)
                        setAmountProduct('')
                    }, 1000);
                }
            })
            .catch(error => {
                setMsg('Số lượng xuất kho không thể lớn hơn số lượng sản phẩm')
                $('.amountProduct').addClass('product_warning')
                }
            )
    }

    // call api set list store
    useEffect(() => {
        let query = '';
        const data = {
            per_page : customersPerPage,
            page: currentPage,
            keyword: encodeURIComponent(keyWord),
            category_id: categorySelected,
            brand_id: brandSelected,
            type_id: typeListSelected,
            status: statusSelected,
        }
        Object.keys(data).map((key,index) => 
            query += (index === 0 ? '?' : "&") + key + '=' + data[key]
        )

        let unmounted = false;
        axios.get(`${ENDPOINT.PRODUCT}` + query, {
            headers: { Authorization: `Bearer ${admin_token}` }
        })
        .then((res) => {
            if (!unmounted) {
                setListProducts(res.data.data.records);
                setNumberPage(res.data.data.total_record)
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [
        customersPerPage, 
        currentPage, 
        brandSelected, 
        typeListSelected, 
        keyWord, 
        categorySelected,
        statusSelected,
        checkSuccess,
        dispatch
    ]);

    // handle delete 
    const handleDelete = (id) => {
        axios.delete(`${ENDPOINT.PRODUCT}/${id}`, config)
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Xóa sản phẩm thành công !')
                    const remainAll = listProducts.filter((store) => store.id !== id)
                    setListProducts(remainAll)
                }
            })
            .catch(error => {
                if(error.request.status === 422) {
                    setMsg('Số lượng xuất đi phải nhỏ hơn số lượng sản phẩm trong kho! Số lượng còn lại trong kho')
                }
            }
        )
    }

    // call api category 
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.LIST_CATEGORY}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setCategory(response.data.data);
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [dispatch])

    // call api type list 
    useEffect(() => {
        let unmounted = false;
        axios.get(`${ENDPOINT.TYPE_LIST}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
            }
        })
        .then(function (response) {
            if (!unmounted) {
                if(response.status === 200) {
                    setTypeList(response.data.data.slice(1,));
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
        return () => { unmounted = true };
    }, [dispatch])

    // call api brands
    useEffect(() => {
    let unmounted = false;
    axios.get(`${ENDPOINT.BRAND_NO_PAGINATE}`, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('User-Admin') 
        }
    })
    .then(function (response) {
        if (!unmounted) {
            if(response.status === 200) {
                setBrands(response.data.data)
            }
        }
    })
    .catch(error => {
        if(error.response.status === 401){
            dispatch(logoutSuccess(true))
        }
    }) 
    return () => { unmounted = true };
    }, [dispatch])

    // Handle search debounce
    const debounce = (func) => {
        let timer;
        return function (...args){
            const context = this;
            if(timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 1000)
        }
    }
    
    const handleInputSearch = (e) => {
        setKeyWord(removeExtraSpace(e.target.value));
        setcurrentPage(1)
    }
    
    // search debounce
    const optimisedChange = debounce(handleInputSearch)

    // prevent enter when search
    $('#searchCategory').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) { 
            e.preventDefault();
            return false;
        }
    });
        

    return (
        <div className="pl-4 pr-4 mt-3">
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        
        <div className="operatelog">
            <div className="d-flex justify-content-between align-self-center">
                <h1 className="customer-title mb-3">Danh sách sản phẩm</h1>
            </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
            <div>
                <Link to ="/main/products/create"><button type="button" className="btn btn-add"> + Thêm</button></Link>
            </div>
        </div>
        <div className="d-flex align-items-center mt-3">
            <div className="input-group col-2 pl-0">
                <select id="selectCate" name="" className="form-control custom_select custom_selectProduct" onChange={handleCategory}> 
                    <option value="">Danh mục</option>
                    {allCategory.map((cate, index) => 
                        <option key={index} value={cate.id}>
                            { (() => {
                                if (cate.checkOrdin === 1) {
                                    return (
                                        cate.name
                                    )
                                } else if (cate.checkOrdin === 2) {
                                    return (
                                        `${'\u00A0\u00A0\u00A0'}` + cate.name
                                    )
                                } else {
                                    return (
                                        `${'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}` + cate.name
                                    )
                                }
                            })()}
                        </option>
                    )}
                </select>
            </div>
            <div className="input-group col-2 pl-0">
                <select id="selectStatus" name="" className="form-control custom_select custom_selectProduct" onChange={handleStatus}> 
                    <option value="">Trạng thái</option>
                    <option value="1">Đang bán</option>
                    <option value="0">Ngừng bán</option>
                </select>
            </div>
            <div className="input-group col-2 pl-0">
                <select name="" id="selectBrands" className="form-control custom_select custom_selectProduct" onChange={handleBrands}> 
                    <option value="">Thương hiệu</option>
                    {brands && brands.map((brand, index) => <option key={index} value={brand.id}>{brand.name}</option>)}
                </select>
            </div>
            <div className="input-group col-2 pl-0">
                <select name="" id="selectTypeList" className="form-control custom_select custom_selectProduct " onChange={handleTypeList}> 
                    <option value="">Loại thuốc</option>
                    {typeList && typeList.map((typeList, index) => <option key={index} value={typeList.id}>{typeList.name}</option>)}
                </select>
            </div>
            <div className="col-3 pl-0 pr-0">
                <form className="form-inline position-relative pt-0 pb-0 app-search d-none d-lg-block">
                    <input 
                        onChange={optimisedChange} 
                        className="form-control"
                        id="searchCategory" 
                        type="search" 
                        placeholder="Tìm kiếm sản phẩm" 
                        aria-label="Search" 
                        style={{ background: "#fff", border: '1px solid #ced4da'}}
                    />
                    <span className="bx bx-search-alt"></span>
                </form>
            </div>
            <button type='button'  className="btn btn-info btn-reload ml-3" onClick={handleRestart}>
                <i className="fas fa-sync-alt"></i>
            </button>
        </div>
        <div className="category-manager-wapper">
        <div className="table-responsive">
            <table className="table project-list-table table-nowrap align-middle table-borderless">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Tên sản phẩm</th>
                    <th>Số lượng</th>
                    <th>Danh mục</th>
                    <th>Thương hiệu</th>
                    <th>Giá bán(VNĐ)</th>
                    <th>Quy cách</th>
                    <th>Sản xuất</th>
                    <th>Hình ảnh</th>
                    <th>Trạng thái</th>
                    <th>Loại thuốc</th>
                    <th>Hành động</th>
                </tr>
                </thead>
                <tbody row="10">
                   {listProducts ? 
                    listProducts && listProducts
                   .map((product, index) => 
                        <tr key={index}>
                            <th style={{'fontWeight': 'normal'}}>
                                {product.id}
                            </th>
                            <th style={{'width': '20%'}}>
                            <Link to={{pathname: `${process.env.REACT_APP_URL}san-pham/${product.slug}`}} target="_blank">
                                {product.name}
                            </Link>
                            </th>
                            <th style={{'fontWeight': 'normal', 'width':'10%'}}>
                                {product.product_stock}
                            </th>
                            <td style={{'width': '10%'}}>
                                {product.category !== null ? product.category.name : ''}
                            </td>
                            <td style={{'width': '10%'}}>
                                {product.brand !== null ? product.brand.name : ''}
                            </td>
                            <td style={{'width': '12%'}}>
                                {
                                    product.type.id === 2 
                                    ? <span>{formatCurrency(product.discount)}</span>
                                    : ''
                                }
                            </td>
                            <td >
                                {product.capacity}
                            </td>
                            <td >
                                {product.made_in}
                            </td>
                            <td >
                                {product.medias !== null 
                                ?
                                product.medias.map(check => 
                                    check.collection_name === 'single' ? <img alt="" style={{'width':'100px', 'height':'100px'}} src={check.image} /> : ''
                                )
                                : 
                                ''}
                            </td>
                            <td >
                                {product.status === 0 ? 'Ngừng bán' : 'Đang bán'}
                            </td>
                            <td >
                                {product.type !== null ? product.type.name : ''}
                            </td>
                            <td style={{'width': '7%'}}>
                                <Link to={`/main/products/edit/${product.id}`}><i className="mdi mdi-pencil font-size-18"></i></Link> 
                                <i style={{'color':'red', 'cursor':'pointer'}} onClick={() => {
                                    handleShow();
                                    setIdStoreCurr(product.id)
                                    setNameStoreCurr(product.name)
                                }} className="mdi mdi-delete font-size-18"></i>
                                 <i className="mdi mdi-plus-circle font-size-18 mr-3" onClick={() => {
                                    handleShowed();
                                     setIdStoreCurr(product.id)
                                     setNameStoreCurr(product.name)
                                }}></i>
                            </td>
                        </tr>
                   ) : ''}
                </tbody>
            </table>
            {numberPage <= 10 ? '' : 
                <Pagination 
                    onChange={(value) => setcurrentPage(value)}
                    total={numberPage}
                    current={currentPage}
                    showSizeChanger= {false}
                /> 
            }
            </div>
        </div>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Xoá sản phẩm</Modal.Title>
            </Modal.Header>
            <Modal.Body>Bạn có chắc chắn muốn xoá sản phẩm <span style={{'color':'blue'}}>{nameStoreCurr}</span> không ?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Thoát
                </Button>
                <Button variant="primary" onClick={() => {
                    handleClose();
                    handleDelete(idStoreCurr)
                }}>
                    Xác nhận
                </Button>
            </Modal.Footer>
        </Modal>
            <Modal show={showed} onHide={handleClosed}>
                <Modal.Header closeButton>
                    <Modal.Title>Thêm sản phẩm vào kho</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Sản phẩm : {nameStoreCurr}</h5>
                    <select name="" id="" className="form-control" onChange={selectImport}>
                        <option value="1">Nhập kho</option>
                        <option value="2">Xuất kho</option>
                    </select>
                    <Form className="label mt-3 mb-2">Số lượng</Form>
                    <input type="number" className="form-control amountProduct" value={amountProduct} onChange={handleAmount} onKeyPress={handleRemoveAmount} />
                    <p style={{'fontSize': '12px'}} className="text-danger msg_name">{validationMsg.amountProduct}</p>
                    <p style={{'fontSize': '12px'}} className="text-danger msg_name">{msg}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={createHouse}>
                        Lưu
                    </Button>
                    <Button variant="secondary" onClick={handleClosed}>
                        Thoát
                    </Button>
                </Modal.Footer>
            </Modal>
    </div>
    )
}

export default Products
