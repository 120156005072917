import React, {useEffect, useState} from 'react';
import metisMenu from 'metismenu';
import $ from 'jquery';
import {
    Link
} from "react-router-dom";
import {AuthApiService} from "../../ultils/EndPoint";
import {pluck} from "../../helpers/function";
import dashboard from '../../assets/images/dashboard 1.svg';
import chat from '../../assets/images/chat (1) 1.svg';
import onlineOrder from '../../assets/images/online-order 1.svg';
import vanCargo from '../../assets/images/van-cargo 1.svg';
import box from '../../assets/images/box 1.svg';
import group from '../../assets/images/Group.svg';
import stock from '../../assets/images/stock 1.svg';
import ratting from '../../assets/images/rating (1) 1.svg';
import browser from '../../assets/images/browser 1.svg';
import newIcon from '../../assets/images/news 1.svg';
import chatBot from '../../assets/images/chatbot 1.svg';
import account from '../../assets/images/account 1.svg';
import bill from '../../assets/images/bill 1.svg';

function Sidebar() {
    useEffect(() => {
        function initMetisMenu() {
            $("#side-menu").metisMenu();
            $("#side1-menu").metisMenu();
            $(".menu-show").metisMenu();
        }

        function initActiveMenu() {
            // === following js will activate the menu in left side bar based on url ====
            $("#sidebar-menu Link").each(function () {
                var pageUrl = window.location.href.split(/[?#]/)[0];
                if (this.href === pageUrl) {
                    $(this).addClass("active");
                    $(this).parent().addClass("mm-active"); // add active to li of the current link
                    $(this).parent().parent().addClass("mm-show");
                    $(this).parent().parent().prev().addClass("mm-active"); // add active class to an anchor
                    $(this).parent().parent().parent().addClass("mm-active");
                    $(this).parent().parent().parent().parent().addClass("mm-show"); // add active to li of the current link
                    $(this).parent().parent().parent().parent().parent().addClass("mm-active");
                }
            })
        }
        function initMenuItemScroll() {
            // focus active menu in left sidebar
            $(document).ready(function(){
                if($("#sidebar-menu").length > 0 && $("#sidebar-menu .mm-active .active").length > 0){
                    var activeMenu = $("#sidebar-menu .mm-active .active").offset().top;
                    if( activeMenu > 300) {
                        activeMenu = activeMenu - 300;
                        $(".vertical-menu .simplebar-content-wrapper").animate({ scrollTop: activeMenu }, "slow");
                    }
                }
            })
        }
        function initHoriMenuActive() {
            $(".navbar-nav Link").each(function () {
                var pageUrl = window.location.href[0];
                if (this.href === pageUrl) { 
                    $(this).addClass("active");
                    $(this).parent().addClass("active");
                    $(this).parent().parent().addClass("active");
                    $(this).parent().parent().parent().addClass("active");
                    $(this).parent().parent().parent().parent().addClass("active");
                    $(this).parent().parent().parent().parent().parent().addClass("active");
                    $(this).parent().parent().parent().parent().parent().parent().addClass("active");
                }
            })
        }
        function initFullScreen() {
            $('[data-toggle="fullscreen"]').on("click", function (e) {
                e.preventDefault();
                $('body').toggleClass('fullscreen-enable');
                if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
                    if (document.documentElement.requestFullscreen) {
                        document.documentElement.requestFullscreen();
                    } else if (document.documentElement.mozRequestFullScreen) {
                        document.documentElement.mozRequestFullScreen();
                    } else if (document.documentElement.webkitRequestFullscreen) {
                        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                    }
                } else {
                    if (document.cancelFullScreen) {
                        document.cancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    }
                }
            });
            document.addEventListener('fullscreenchange', exitHandler );
            document.addEventListener("webkitfullscreenchange", exitHandler);
            document.addEventListener("mozfullscreenchange", exitHandler);
            function exitHandler() {
                if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                    $('body').removeClass('fullscreen-enable');
                }
            }
        }
        function initRightSidebar() {
            // right side-bar toggle
            $('.right-bar-toggle').on('click', function (e) {
                $('body').toggleClass('right-bar-enabled');
            });
    
            $(document).on('click', 'body', function (e) {
                if ($(e.target).closest('.right-bar-toggle, .right-bar').length > 0) {
                    return;
                }
    
                $('body').removeClass('right-bar-enabled');
                return;
            })
        }

        function initDropdownMenu() {
            if(document.getElementById("topnav-menu-content")){
                var elements = document.getElementById("topnav-menu-content").getElementsByTagName("Link");
                for(var i = 0, len = elements.length; i < len; i++) {
                    elements[i].onclick = function (elem) {
                        if(elem.target.getAttribute("to")) {
                            elem.target.parentElement.classList.toggle("active");
                            elem.target.nextElementSibling.classList.toggle("show");
                        }
                    }
                }
            }
        }

        initMetisMenu()
        initActiveMenu()
        initMenuItemScroll()
        initHoriMenuActive()
        initFullScreen()
        initRightSidebar()
        initDropdownMenu()
    }, [])

    const [permissionWebsite, setPermissionWebsite] = useState(false)
    const [permissionStore, setPermissionStore] = useState(false)
    const [permissionBanner, setPermissionBanner] = useState(false)
    const [permissionStaticPage, setPermissionStaticPage] = useState(false)
    const [permissionService, setPermissionService] = useState(false)
    const [permissionPartner, setPermissionPartner] = useState(false)
    const [permissionCatalog, setPermissionCatalog] = useState(false)
    const [permissionNews, setPermissionNews] = useState(false)
    const [permissionBrand, setPermissionBrand] = useState(false)
    const [permissionCategory, setPermissionCategory] = useState(false)
    const [permissionProduct, setPermissionProduct] = useState(false)
    const [permissionGroup, setPermissionGroup] = useState(false)
    const [permissionCoupon, setPermissionCoupon] = useState(false)
    const [permissionDeal, setPermissionDeal] = useState(false)
    const [permissionImportExport, setPermissionImportExport] = useState(false)
    const [permissionFaq, setPermissionFaq] = useState(false)
    const [permissionReview, setPermissionReview] = useState(false)
    const [permissionTransport, setPermissionTransport] = useState(false)
    const [permissionConfigTransport, setPermissionConfigTransport] = useState(false)
    const [permissionOrder, setPermissionOrder] = useState(false)
    const [permissionOrderDraft, setPermissionOrderDraft] = useState(false)
    const [permissionAdmin, setPermissionAdmin] = useState(false)
    const [permissionRole, setPermissionRole] = useState(false)
    const [permissionPermission, setPermissionPermission] = useState(false)
    const [permissionUser, setPermissionUser] = useState(false)
    const [permissionPrescription, setPermissionPrescription] = useState(false)
    const [permissionScript, setPermissionScript] = useState(false)


    function CheckPermission(allPermission, permission){
        return allPermission.includes(permission)
    }

    useEffect(() => {
        AuthApiService.me().then(r => {
            if (r.status) {
                let extraPermission = r.data.permissions
                let permissionFromRole = []
                let roles = r.data.roles
                if (roles.length) {
                    roles.forEach(role => {
                        if (role.permissions.length) {
                            permissionFromRole = permissionFromRole.concat(role.permissions)
                        }
                    })
                }
                let result = extraPermission.concat(permissionFromRole)
                let allPermission = pluck(result, "name")
                setPermissionWebsite(CheckPermission(allPermission, "Quản lý website"))
                setPermissionStore(CheckPermission(allPermission, "Quản lý cửa hàng"))
                setPermissionBanner(CheckPermission(allPermission, "Quản lý banner"))
                setPermissionStaticPage(CheckPermission(allPermission, "Quản lý trang tĩnh"))
                setPermissionService(CheckPermission(allPermission, "Quản lý dịch vụ"))
                setPermissionPartner(CheckPermission(allPermission, "Quản lý đối tác"))

                setPermissionCatalog(CheckPermission(allPermission, "Quản lý danh mục tin tức"))
                setPermissionNews(CheckPermission(allPermission, "Quản lý tin tức"))
                setPermissionProduct(CheckPermission(allPermission, "Quản lý sản phẩm"))
                setPermissionBrand(CheckPermission(allPermission, "Quản lý thương hiệu"))
                setPermissionCategory(CheckPermission(allPermission, "Quản lý danh mục sản phẩm"))
                setPermissionGroup(CheckPermission(allPermission, "Quản lý nhóm sản phẩm"))
                setPermissionDeal(CheckPermission(allPermission, "Quản lý deal"))

                setPermissionCoupon(CheckPermission(allPermission, "Quản lý mã giảm giá"))
                setPermissionImportExport(CheckPermission(allPermission, "Quản lý xuất nhập kho"))
                setPermissionFaq(CheckPermission(allPermission, "Quản lý Faq"))
                setPermissionReview(CheckPermission(allPermission, "Quản lý đánh giá"))
                setPermissionTransport(CheckPermission(allPermission, "Quản lý vận chuyển"))
                // setPermissionConfigTransport(CheckPermission(allPermission, "Quản lý cấu hình phí vận chuyển theo khu vực"))
                setPermissionOrder(CheckPermission(allPermission, "Quản lý đơn hàng"))
                setPermissionAdmin(CheckPermission(allPermission, "Quản lý admin"))

                setPermissionRole(CheckPermission(allPermission, "Quản lý vai trò"))
                setPermissionPermission(CheckPermission(allPermission, "Quản lý phân quyền"))
                setPermissionUser(CheckPermission(allPermission, "Quản lý người dùng"))
                setPermissionPrescription(CheckPermission(allPermission, "Quản lý đơn thuốc"))
                setPermissionScript(CheckPermission(allPermission, "Quản lý câu hỏi tự động"))
            }
        })

    }, [])
    return (
        <div className="vertical-menu" >
            <div data-simplebar="init" className="h-100">
                <div className="simplebar-wrapper">
                    <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer"/>
                    </div>
                    <div className="simplebar-mask" >
                        <div className="simplebar-offset">
                            <div className="simplebar-content-wrapper">
                                <div id="sidebar-menu">
                                    <ul className="metismenu list-unstyled mm-show menu-show">
                                        {/* Dashboard */}
                                        <li>
                                            <Link to='/main/dashboard' className="waves-effect active" >
                                                <img className="mr-2" src={dashboard} alt=""></img>
                                                <span key="t-layouts">Dashboard</span>
                                            </Link>
                                        </li>
                                        {/* Tư vấn khách hàng */}
                                        <li>
                                            <Link to='/main/chat' className="waves-effect active" >
                                                <img className="mr-2" src={chat} alt=""></img>
                                                <span key="t-layouts">Tư vấn Khách hàng</span>
                                                {/*<span className="badge rounded-pill bg-info float-end">04</span>*/}
                                            </Link>
                                        </li>
                                        {/* Quản lý đơn hàng */}
                                        <li hidden={!permissionOrder}>
                                            <Link to='#' className="has-arrow waves-effect" >
                                                <img className="mr-2" src={onlineOrder} alt=""></img>
                                                <span key="t-layouts">Quản lý  Đơn hàng</span>
                                            </Link>
                                            <ul className="sub-menu  mm-collapse">
                                                <li>
                                                    <Link to='/main/list-orders' key="t-light-sidebar">Quản lý đơn hàng</Link>
                                                </li>
                                                <li>
                                                    <Link to='/main/list-orders-draft' key="t-light-sidebar">Quản lý đơn hàng nháp</Link>
                                                </li>
                                                <li>
                                                    <Link to='/main/list-ticket-delivery' key="t-light-sidebar">Quản lý phiếu giao hàng</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        {/* Quản lý vận chuyển */}
                                        <li hidden={!permissionTransport} className="metismenu list-unstyled mm-show" id="side-menu">
                                            <Link to='#' className="has-arrow waves-effect" >
                                                <img className="mr-2" src={vanCargo} alt=""></img>
                                                <span key="t-layouts">Quản lý Vận chuyển</span>
                                            </Link>
                                            <ul className="sub-menu  mm-collapse">
                                                {/* <li hidden={!permissionTransport}>
                                                    <Link to='/main/shippings' key="t-light-sidebar">Cấu hình vận chuyển</Link>
                                                </li> */}
                                                <li hidden={!permissionTransport}>
                                                    <Link to='/main/shippings-manager' key="t-light-sidebar">Quản lý vận chuyển</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        {/* ====== Sản phẩm ======== */}
                                        <li className="menu-title" hidden={!permissionBrand && !permissionCategory && !permissionProduct && !permissionGroup && !permissionCoupon && !permissionDeal && !permissionImportExport && !permissionFaq && !permissionReview} style={{color:'#FFF'}} key="t-menu-1">Sản phẩm</li>
                                        <li hidden={!permissionBrand && !permissionCategory && !permissionProduct && !permissionGroup}>
                                            <Link to='#' className="has-arrow waves-effect" >
                                                <img className="mr-2" src={box} alt=""></img>
                                                <span key="t-layouts">Quản lý Sản phẩm</span>
                                            </Link>
                                            <ul className="sub-menu  mm-collapse">
                                                <li hidden={!permissionBrand}>
                                                    <Link to='/main/brands' key="t-light-sidebar">Quản lý thương hiệu</Link>
                                                </li>
                                                <li hidden={!permissionCategory}>
                                                    <Link to='/main/products/category' key="t-light-sidebar">Quản lý danh mục</Link>
                                                </li>
                                                <li hidden={!permissionProduct}>
                                                    <Link to='/main/products' key="t-light-sidebar">Quản lý sản phẩm</Link>
                                                </li>
                                                <li hidden={!permissionGroup}>
                                                    <Link to='/main/products/groups' key="t-light-sidebar">Quản lý nhóm sản phẩm</Link>
                                                </li>
                                                <li  hidden={!permissionProduct}>
                                                    <Link to='/main/gift-exchange-point' key="t-light-sidebar">Quản lý quà đổi điểm</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li hidden={!permissionCoupon && !permissionDeal}>
                                            <Link to='#' className="has-arrow waves-effect" >
                                                <img className="mr-2" src={group} alt=""></img>
                                                <span key="t-layouts">Quản lý Khuyến mại</span>
                                            </Link>
                                            <ul className="sub-menu  mm-collapse">
                                                <li hidden={!permissionCoupon}>
                                                    <Link to='/main/coupon' key="t-light-sidebar">Quản lý mã giảm giá</Link>
                                                </li>
                                                <li hidden={!permissionDeal}>
                                                    <Link to='/main/deal' key="t-light-sidebar">Quản lý deal</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li hidden={!permissionImportExport}>
                                            <Link to='/main/warehouse' className="waves-effect" >
                                                <img className="mr-2" src={stock} alt=""></img>
                                                <span key="t-layouts">Quản lý kho</span>
                                            </Link>
                                        </li>
                                        <li hidden={!permissionFaq && !permissionReview}>
                                            <Link to='#' className="has-arrow waves-effect" >
                                                <img className="mr-2" src={ratting} alt=""></img>
                                                <span key="t-layouts">FAQ & Review</span>
                                            </Link>
                                            <ul className="sub-menu  mm-collapse">
                                                <li hidden={!permissionFaq}>
                                                    <Link to='/main/faq' key="t-light-sidebar">Quản lý FAQ</Link>
                                                </li>
                                                <li hidden={!permissionReview}>
                                                    <Link to='/main/order-review' key="t-light-sidebar">Quản lý đánh giá</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        {/* ====== Quản lý website ======== */}
                                        <li hidden={!permissionWebsite && !permissionStore && !permissionStaticPage && !permissionPartner && !permissionBanner && !permissionService && !permissionCatalog && !permissionNews && !permissionScript} className="menu-title" style={{color:'#FFF'}} key="t-menu">Quản lý  website</li>
                                        <li hidden={!permissionWebsite && !permissionStore && !permissionStaticPage && !permissionPartner && !permissionBanner && !permissionService}>
                                            <Link to='#' className="has-arrow waves-effect" >
                                                <img className="mr-2" src={browser} alt=""></img>
                                                <span key="t-layouts">Quản lý trang chủ</span>
                                            </Link>
                                            <ul className="sub-menu mm-collapse">
                                                <li hidden={!permissionWebsite}>
                                                    <Link to='/main/website' key="t-light-sidebar">Thông tin website</Link>
                                                </li>
                                                <li hidden={!permissionStore}>
                                                    <Link to='/main/store' key="t-light-sidebar">Địa chỉ cửa hàng</Link>
                                                </li>

                                                <li hidden={!permissionStaticPage}>
                                                    <Link to='/main/static-page' className="waves-effect" >
                                                        <span key="t-layouts">Trang tĩnh</span>
                                                    </Link>
                                                </li>
                                                <li hidden={!permissionService}>
                                                    <Link to='/main/services' className="waves-effect" >
                                                        <span key="t-layouts">Dịch vụ</span>
                                                    </Link>
                                                </li>
                                                <li hidden={!permissionPartner}>
                                                    <Link to='/main/partner' className="waves-effect" >
                                                        <span key="t-layouts">Đối tác</span>
                                                    </Link>
                                                </li>
                                                <li hidden={!permissionBanner}>
                                                    <Link to='/main/banners' className="waves-effect" >
                                                        <span key="t-layouts">Banner</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li hidden={!permissionCatalog && !permissionNews} className="metismenu list-unstyled mm-show menu-show">
                                            <Link to='#' className="has-arrow waves-effect" >
                                                <img className="mr-2" src={newIcon} alt=""></img>
                                                <span key="t-layouts">Quản lý tin tức</span>
                                            </Link>
                                            <ul className="sub-menu mm-collapse">
                                                <li hidden={!permissionCatalog}>
                                                    <Link to='/main/catalog-news' className="waves-effect" >
                                                        <span key="t-layouts">Danh mục tin tức</span>
                                                    </Link>
                                                </li>
                                                <li hidden={!permissionNews}>
                                                    <Link to='/main/news' className="waves-effect" >
                                                        <span key="t-layouts">Quản lý tin tức</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li hidden={!permissionScript} className="metismenu list-unstyled mm-show">
                                            <Link to='/main/scripts'>
                                                <img className="mr-2" src={chatBot} alt=""></img>
                                                <span key="t-layouts">Câu hỏi tự động</span>
                                            </Link>
                                        </li>
                                        {/* ==========Khác===================== */}
                                        <li hidden={!permissionAdmin && !permissionRole && !permissionPermission  && !permissionUser  && !permissionPrescription } className="metismenu list-unstyled mm-show menu-show">
                                            <li className="menu-title" style={{color:'#FFF'}} key="t-menu-2">Khác</li>
                                        </li>
                                        <li hidden={!permissionAdmin && !permissionRole && !permissionPermission && !permissionUser} className="metismenu list-unstyled mm-show menu-show">
                                            <Link to='#' className="has-arrow waves-effect" >
                                                <img className="mr-2" src={account} alt=""></img>
                                                <span key="t-layouts">Quản lý Người dùng</span>
                                            </Link>
                                            <ul className="sub-menu  mm-collapse">
                                                <li hidden={!permissionAdmin}>
                                                    <Link to='/main/admin' key="t-light-sidebar">Quản lý admin</Link>
                                                </li>
                                                <li hidden={!permissionRole}>
                                                    <Link to='/main/role' key="t-light-sidebar">Quản lý vai trò</Link>
                                                </li>
                                                <li hidden={!permissionPermission}>
                                                    <Link to='/main/permission' key="t-light-sidebar">Quản lý phân quyền</Link>
                                                </li>
                                                <li hidden={!permissionUser}>
                                                    <Link to='/main/users' key="t-light-sidebar">Quản lý người dùng</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        {/* <li hidden={!permissionPrescription}>
                                            <Link to='/main/prescription'>
                                                <img className="mr-2" src={bill} alt=""></img>
                                                <span key="t-layouts">Quản lý Đơn thuốc</span>
                                            </Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="simplebar-placeholder"></div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;
